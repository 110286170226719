import React from "react"
import Footer from "./footer"
import {Header} from "./NavHeader";

const Layout = ({location, children, hideNewsletterForm=false}) => {
    const rootPath = `${__PATH_PREFIX__}/`
    const isHomePage = location.pathname === rootPath


    return (
        <div>
            <div style={{padding: 0}}>
                <Header isHomePage={isHomePage} location={location}/>
            </div>


            <main style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: 1200,
                padding: 0,
                paddingBottom: 16,
                marginTop: (isHomePage ? 0 : 48)
            }}>{children}</main>
            <Footer hideNewsletterForm={hideNewsletterForm}/>
        </div>
    )
}

export default Layout
