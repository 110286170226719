import React from "react"
import { graphql } from "gatsby"
import "./blog-post.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutInner from "../components/layoutInner";
import {withStyles} from "@material-ui/core";
import * as PATHS from '../utils/paths'
import {joinPaths} from "../utils/index";

const workPostStyles = theme => ({
    root: {},

    descriptionText: {
        color: theme.palette.text.secondary,
    },
    featuredImage: {
        maxWidth: 800,
        marginLeft: "auto",
        marginRight: "auto",
        border: "1px solid #04090D",
        "& img": {
            maxWidth: 800,
            marginTop: 0,
            marginBottom: 0,
        }
    },
    featuredImageContainer: {
        paddingTop: 32,
        position: "relative"
    },
    featuredImageBGContainer: {
        marginTop: 80,
        marginBottom: 80,
        zIndex: -1,
        position: "absolute",
        // [theme.breakpoints.up('sm')]: {
        //     height: 400,
        // },
        background: "#dddddd",
        width: "100%",
      // height: "100%",
        top: 0,
        bottom: 0,
    }
});

const _WorkPostTemplate = ({ classes, data, pageContext, location }) => {
  const post = data.markdownRemark

  return (
    <Layout location={location}>
        <SEO
            location={location}
            title={post.frontmatter.pageTitle || post.frontmatter.title}
            description={post.frontmatter.description || post.excerpt}
            imagePath={joinPaths([PATHS.CDN, post.fields.collection, post.fields.slug, "hero.png"])}
      />
      <article className={'work-page'}>
          <header style={{marginBottom: 16}}>
              <LayoutInner>
                  <h1
                      style={{
                          marginTop: 24,
                          marginBottom: 0,
                          marginLeft: 0,
                          marginRight: 0
                      }}
                  >
                      {post.frontmatter.title}
                  </h1>

                  <div className={classes.descriptionText}
                       style={{
                           fontSize: 22,
                           lineHeight: "32px",
                           marginTop: 8,
                       }}>{post.frontmatter.description}</div>

              </LayoutInner>

          </header>

          <div className={classes.featuredImageContainer}>

            <div className={classes.featuredImageBGContainer}
                       style={{backgroundColor: post.frontmatter.featuredImageBGColor}}></div>


              <img
                  className={classes.featuredImage}
                   src={joinPaths([PATHS.CDN, post.fields.collection, post.fields.slug, "hero.png"])}
                alt={post.frontmatter.featuredImageAltText}
              />
            </div>
            <div style={{
                marginTop: 64,
                marginBottom: 32,
            }}>
                <LayoutInner>
                <section
                    style={{
                        marginTop: 32
                    }}
                    dangerouslySetInnerHTML={{ __html: post.html }} />
                </LayoutInner>
            </div>

      </article>

    </Layout>
  )
}

const WorkPostTemplate = withStyles(workPostStyles)(_WorkPostTemplate)
export default WorkPostTemplate

export const pageQuery = graphql`
  query WorkPageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      
      html
      fields {
        collection
        slug
      }
      frontmatter {
        title
        description
        featuredImageAltText
        featuredImageBGColor
      }
    }
  }
`
